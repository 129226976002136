<template>
  <div></div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import router from "@/router";
import axios from "axios";

export default defineComponent({
  name: 'CorpEmailAuth',

  data () {
    return {
      toMail: this.$route.params.toMail,
      authCode: this.$route.params.authCode
    };
  },

  mounted () {
    this.getEmailAuth();
  },

  methods: {
    /**
     * * 이메인 인증
     * * 인증
     */
    getEmailAuth () {
      const toMail = this.$route.params.toMail;
      const authCode = this.$route.params.authCode;

      axios
        .post("/api/corp/checkAuthEmail", { toMail, authCode })
        .then(res => {
          if (res.data) {
            router.push({ name: 'brand/login' });
          } else {
            alert("인증에 실패했습니다.");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
});
</script>
